<template>
  <div>
    <vuestic-widget :loading="loading">
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">COVID-19 Pop-Up</span>
          <div class="d-flex justify-content-between align-items-end">
            <div class="mr-2">
              <a :href="'//' + landingUrl + '/popup'" target="_blank" class="btn btn-primary btn-sm landing-page-btn">Go To Landing Page</a>
            </div>
            <div v-if="hasAgencyStripeConfig">
              <div class="d-inline-block mr-2" style="width: 150px;">
                <text-input type="number" :immediate="false" name="Fee" prefix="$" label="Script Fee" tooltip="Enable Stripe Inteagraion To Accept Payments" v-model="fee" placeholder="Enter the Fee..."/>
              </div>
              <div class="d-inline-block" style="width: 200px; margin-bottom: 0;">
                <vuestic-switch v-model="paymentEnabled">
                  <span slot="trueTitle">Yes</span>
                  <span slot="falseTitle">No</span>
                </vuestic-switch>
              </div>
              <button class="btn btn-primary btn-sm d-inline-block ml-3" @click="updatePopupPayment">Update</button>
            </div>
          </div>
        </div>
      </template>
      <div>
        <h4>Leads</h4>
        <datatable v-bind="datatable" class="le-datatable"></datatable>
      </div>
    </vuestic-widget>
  </div>
</template>

<script>
import components from "../common/tables/comps";

export default {
  components: {  },

  data () {
    return {
      fee: null,
      paymentEnabled: null,
      datatable: {
        HeaderSettings: false,
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          const cols = [
            { title: '#', field: 'id', label: '#', sortable: false, tdStyle: {fontStyle: "normal"}, tdClass: "center" },
            { title: 'Full name', sortable: true, field: 'full_name', tdStyle: {fontStyle: "normal"}, tdClass: "center"},
            { title: 'Website', sortable: true, field: 'website', tdStyle: {fontStyle: "normal"}, tdClass: "center"},
            { title: 'Email', field: 'email', sortable: true, tdStyle: {fontStyle: "normal"}, tdClass: "center", tdComp: "Email"},
            { title: 'Phone', field: 'phone', sortable: true, tdStyle: {fontStyle: "normal"}, tdClass: "center"},
            { title: 'Created at', field: 'created_at', sortable: true, tdStyle: {fontStyle: "normal"}, tdClass: "center", tdComp: "TdNestedDateValue"},
          ]

          return cols
        })(),
        data: [],
        selection: [],
        query: {},
        total: 0,
      },
      loading: false,
    }
  },

  mounted () {
    this.paymentEnabled = this.$store.state.auth.user.agency.popupPaymentSetting.paymentEnabled;
    this.fee = this.$store.state.auth.user.agency.popupPaymentSetting.fee;
  },

  methods: {
    updatePopupPayment() {
      const args = {
        id: this.$store.state.auth.user.agency.id,
        data: {
          fee: this.fee,
          paymentEnabled: this.paymentEnabled
        }
      }
      this.loading = true
      this.$store.dispatch('agency/updatePopupPayment', args)
        .then(res => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleQueryChange () {
      this.loading = true
      this.$store.dispatch('agency/getModalUsers', this.datatable.query)
        .then(res => {
          this.loading = false
          this.datatable.data = res.data.data
          this.datatable.total = res.data.meta.total
        })
        .catch(() => {
          this.loading = false
        })
    },
  },

  computed: {
    landingUrl () {
      if (this.$store.state.auth.user)
      {
        const whitelabel = {...this.$store.state.auth.user.agency.whitelabel}
        const subdomain_url = this.$store.state.auth.user.agency.subdomain_url + '.' + this.$store.state.app.sitedata.whitelabel_domain;
        return (whitelabel.type == 'domain') ? (whitelabel.domain ? whitelabel.domain : subdomain_url) : subdomain_url;
      }
      return ''
    },

    hasAgencyStripeConfig() {
      return this.$store.getters['auth/hasAgencyStripeConfig'];
    },

    sitedata () {
      return this.$store.state.app.sitedata
    }
  },

  watch: {
    'datatable.query': {
      handler () {
        this.handleQueryChange()
      },
      deep: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.landing-page-btn {
  display: inline-block;
}
.atom-spinner {
  display: inline-block;
}
.custom-script-textarea {
  background-color: #e8e8e8;
  border: 2px solid #eee;
  font-size: 14px;
  line-height: 22px;
  word-break: break-all;

  &:active,
  &:focus,
  &:hover {
    border: 2px solid #ccc;
    box-shadow: none !important;
  };
}
</style>

<style lang="scss">
.edit-page-modal {
  .modal-dialog {
    max-width: 100%;
    width: 1080px;
    .ql-editor {
      color: #414141;
    }
  }
}
</style>
